import { render, staticRenderFns } from "./use-record.vue?vue&type=template&id=2e90d3b7&scoped=true&"
import script from "./use-record.vue?vue&type=script&lang=js&"
export * from "./use-record.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e90d3b7",
  null
  
)

export default component.exports